<template>
  <div class="wallet-terms">
    <div class="container-fluid mt--5">
      <div class="row">
        <div class="col-12 col-xl-3">
          <div class="card mb-3 h-100">
            <div class="card-header">
              <h3 class="mb-0">
                <i class="el-icon-tickets"></i> Terms and Conditions
              </h3>
            </div>
            <div class="card-body">
              <el-tree
                  ref="tree"
                node-key="id"
                :data="data"
                :props="defaultProps"
                :default-expanded-keys=[1]
                :highlight-current="true"
                @node-click="nodeSelect"
              ></el-tree>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-9">
          <div class="card mb-3 h-100">
          
            <div class="card-body">
              <div class="markdown-render" v-html="markdownToHtml()"></div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <transition name="fade">
      <div id="pagetop" class="position-fixed right-0 bottom-4 cursor-pointer" v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
            stroke="#4a5568"
            stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6"/>
        </svg>
      </div>
    </transition> 
  </div>
  
</template>

<script>
import axios from 'axios'
import marked from 'marked';
export default {
  computed: {
    isBusiness() {
      return this.$store.getters.business
    },
  },
  data() {
    return {
      markdown:  "",
      data: [
        {
          id: 1,
          label: "Agreement with AB PLUS",
          children: [
            {
              id: 15,
              label: "FX and Payment Term & Condition",
              content: "/ab-money/terms-and-conditions.md",
            },
            {
               id: 16,
              label: "Framework Contract",
              content: "/ab-money/framework-contract.md",
            },
            {
               id: 17,
              label: "Single Payment Contract",
              content: "/ab-money/single-payment-contract.md",
            },
            {
               id: 18,
              label: "General Data Protection Regulation (GDPR)",
              content: "/ab-money/gdpr.md",
            },
          ],
        },
        {
          id: 2,
          label: "Risk Assessment",
          children: [
            {
              id: 21,
              label: "Prohibited Business",
              content: "/risk/prohibited-business.md",
            },
            {
              id: 22,
              label: "Prohibited Countrie",
              content: "/risk/prohibited-countries.md",
            },
          ],
        },
        {
          id: 3,
          label: "IT Security",
          children: [
            {
              id: 31,
              label: "Awareness for Customers",
              content: "/it-security/customer.md",
            },
            {
              id: 32,
              label: "Application Policy",
              content: "/it-security/app-policy.md",
            },
            {
              id: 33,
              label: "Software Monitoring",
              content: "/it-security/software-monitoring.md",
            },
            {
              id: 34,
              label: "Cookies & Privacy Policy",
              content: "/it-security/privacy-policy.md",
            },
          ],
        },
        {
          id: 4,
          label: "Customer Awareness",
          children: [
            {
              id: 41,
              label: "Safeguard Customer Funds",
              content: "/customer-awareness/safeguard-customer-funds.md",
            },
            {
              id: 42,
              label: "Foreign Exchange Risk",
              content: "/customer-awareness/foreign-exchange-risk.md",
            },
            {
              id: 43,
              label: "Economic Risk",
              content: "/customer-awareness/economic-risk.md",
            },
            {
              id: 44,
              label: "AB Money Consumer Duty Policy",
              content: "/customer-awareness/ab-money-consumer-duty-policy.md",
            },
            {
              id: 45,
              label: "Vulnerable Customer Policy",
              content: "/customer-awareness/vulnerable-customer-policy.md",
            },
            {
              id: 46,
              label: "Conflict of Interest Policy",
              content: "/customer-awareness/conflict-of-interest-policy.md",
            },
            {
              id: 47,
              label: "Modern Slavery Policy",
              content: "/customer-awareness/modern-slavery-policy.md",
            },
        
          ],
        },
      ],
      termsPersonal : [
        {
          "id": 11,
          "label": "Consumer Account Term & Condition",
          "content": "/ab-plus/consumer-account-term-condition.md",
        },
        {
          "id": 13,
          "label": "Consumer Card Term & Condition",
          "content": "/ab-plus/consumer-card-term-condition.md",
        },
        {
          "id": 14,
          "label": "Personal Term & Condition",
          "content": "/ab-plus/terms-and-conditions-personal.md",
        },
      ],
      termsBusiness : [
        {
          "id": 11,
          "label": "Corporate Account Term & Condition",
          "content": "/ab-plus/corporate-account-term-condition.md",
        },
        {
          "id": 13,
          "label": "Corporate Card Term & Condition",
          "content": "/ab-plus/corporate-card-term-condition.md",
        },
        {
          "id": 14,
          "label": "Business Term & Condition",
          "content": "/ab-plus/terms-and-conditions-business.md",
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      scTimer: 0,
        scY: 0,
    };
  },

  methods: {
    nodeSelect(data) {
      if (data.content) {
        this.getMarkdownFile(data.content)
      }
    },
    getMarkdownFile(path){
      axios.get('/terms-conditions'+path).then((response)=>{
        this.markdown = response.data;
      });
    },
    markdownToHtml(){
     return marked.parse(this.markdown);
   },
   handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
  },
  mounted(){
    if(this.isBusiness){
      this.data[0].children = [...this.termsBusiness, ...this.data[0].children];
    }else{
      this.data[0].children = [...this.termsPersonal, ...this.data[0].children];
    }

    setTimeout(()=>{
      this.$refs.tree.setCurrentKey(11);
      let result = this.$refs.tree.getCurrentNode();
      this.getMarkdownFile(result.content)
      window.addEventListener('scroll', this.handleScroll);
    }, 400)
  
  }
};
</script>

<style lang="scss">
.wallet-terms{
  .markdown-render{
    min-height: 400px;
    *{font-size: 14px;}
  }

  img{
    width: 100%;
  }
}
</style>